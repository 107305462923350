/**
 * Type: ユーティリティーパーツ
 * What: RFのステータス表示変換
 */

const RfStatusFunc = (status) => {
  if (status === 'operation') {
    return '運行中';
  }
  if (status === 'suspended') {
    return '停止中';
  }
  if (status === 'maintenance') {
    return 'メンテナンス中';
  }
  return false;
};

export default RfStatusFunc;
