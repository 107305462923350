/**
 * Type: コンポーネント
 * What: アコーディオン
 */
import React, { useEffect, useState } from 'react';
import * as Scroll from 'react-scroll';

export const Accordion = (props) => {
  const [Acc, setAcc] = useState(false);
  const {
    Name, Title, Close, CloseSimple, Icon, bg,
  } = props;

  const toggle = (e) => {
    e.preventDefault();
    setAcc(!Acc);
    if (Acc) {
      Scroll.scroller.scrollTo(Title, {
        duration: 1200,
        // delay: 60,
        offset: -127,
        smooth: 'easeInOutQuint',
      });
    }
  };

  useEffect(() => {
    props.open && setAcc(true);
  }, [props.open]);

  return (
    <>
      <div className={`accordion ${Acc ? 'open' : 'close'} ${CloseSimple ? 'simple' : ''} ${bg || ''}`} name={Title}>
        <div className="accordion-content">
          <h2 className="accordion-title">{Title}</h2>
          <div className="accordion-inner">
            {props.children && props.children}
          </div>
        </div>
        <button type="button" onClick={toggle} className={`accordion-btn ${bg || ''}`}>
          <span className="close-title">{Name}</span>
          <span className="close-text">
            {Close}
          </span>
          {Icon || (
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="16px">
              <path fillRule="evenodd" fill="rgb(8, 162, 242)" d="M-0.000,8.407 L-0.000,15.193 C-0.000,15.866 0.744,16.242 1.250,15.825 L9.532,9.010 C9.813,8.779 10.210,8.779 10.490,9.010 L18.748,15.804 C19.256,16.218 20.000,15.842 20.000,15.168 L20.000,8.384 C20.000,8.138 19.892,7.905 19.706,7.751 L10.490,0.172 C10.210,-0.058 9.813,-0.058 9.532,0.172 L0.294,7.776 C0.108,7.928 -0.000,8.162 -0.000,8.407 " />
            </svg>
          )}
        </button>
      </div>
    </>
  );
};

export default Accordion;
