/**
 * Type: ページ
 * What: 絶景3兄弟 SMBC日興証券-すべZOページ
 */
import React from 'react';
import { graphql, Link } from 'gatsby';
import { scroller } from 'react-scroll';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import { Btn, Text, TitleZekkei } from '../../components/Btn';
import Image from '../../util/Image';
import BreadcrumbComponent from '../../components/Breadcrumb';
import SimpleSwiper from '../../components/Slider.fade';
import RfStatusComponent from '../../components/PostList/RfStatusComponent';
import { Accordion } from '../../components/Accordion';
import ModalContent from '../../components/ZekkeiModal';

const pageTitle = 'すべZO SMBC日興証券';
const pageDescription =
  'E・ZO FUKUOKAの屋上アトラクション。地上40mの高さからビルの壁面に沿って地上まで一気に滑り降りる全長100mのチューブ型スライダー。体験できるのは日本でここだけ！';
const pageSlug = 'zekkei-brothers';
// const pageLogo = `logo-${pageSlug}.png`;
// const pagebtnLogo = `btn-${pageSlug}.png`;

const sliderDataobj = [
  {
    filename: 'img-attraction-subezo.jpg',
  },
];
export const query = graphql`
  {
    site {
      siteMetadata {
        ticket
        ticketReady
        uuidSubeZO
        zekkeiReady
      }
    }
  }
`;

// Data Props Template
const Template = ({ data }) => {
  const { ticket } = data.site.siteMetadata;
  const uuid = data.site.siteMetadata.uuidSubeZO;
  const isTicket = data.site.siteMetadata.ticketReady;
  const isZekkei = data.site.siteMetadata.zekkeiReady;
  const scrollToTarget = (elem, offset) => {
    const $offset = offset || -110;
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };
  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-8 arrow">
        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-8">
                  <span>R</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src="logo-zekkei-subezo-h.png" alt={pageTitle} />
                </h2>
              </div>

              {isZekkei === '1' ? <RfStatusComponent col="1" /> : null}

              <div className="overview">
                {/* <h2 className="text is-13 is-6-sp fwb tal lh-5">高低差４０M・全長１００Mの建物直結型すべり台体験できるのは日本でここ（E・ZO）だけ！</h2> */}
                <p className="text">
                  <span className="fwb">
                    地上40mの高さからビルの壁面に沿って地上まで一気に滑り降りる全長100mのチューブ型スライダー。体験できるのは日本でBOSS
                    E･ZO FUKUOKAだけ！
                  </span>
                  <br />
                  ※建造物に付随したチューブ型スライダーとして日本初
                </p>
              </div>
              {isTicket === '1' ? (
                <ModalContent ticket={ticket} uuid={uuid} page="subezo" />
              ) : null}
              {/* <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>
                    新型コロナウイルス感染防止の対策について
                  </span>
                </Link>
              </div> */}
              <div className="flex flex-center mt-6">
                <div className="half-padding sp-mb10">
                  <div className="youtube-wrap-responsive cn-hide">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/irJF4cd_FAA"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*        <div className="container">
          <div className="inner">
            <div className="section-link">
              <ul>
                <li><a href="#attraction" onClick={() => scrollToTarget('attraction', -66)}><span className="arrow">劇場紹介</span></a></li>
                <li><a href="#information" onClick={() => scrollToTarget('information', -30)}><span className="arrow">料金</span></a></li>
                <li><a href="#"><span className="arrow">よくある質問</span></a></li>
              </ul>
            </div>
          </div>
        </div> */}
      </section>
      <section className="section sc-attraction bg-13 none" id="attraction">
        <div className="container">
          <h2 className="title is-2 jp shadow3 sp-mb10">
            <TitleZekkei width={760} shadow="50.5%">
              ビル壁面を滑走するスライダー
            </TitleZekkei>
          </h2>

          <div className="flow-row mb80">
            <div className="col12 flex">
              <div className="row">
                <Image className="half" filename="img-subezo1.jpg" />
                <Image className="half" filename="img-subezo2.jpg" />
              </div>
            </div>
          </div>

          <h2 className="title is-2 jp shadow3 sp-mb10">
            <TitleZekkei width={760} shadow="50.5%">
              スタート前から楽しめる！
            </TitleZekkei>
          </h2>
          <div className="content mb60">
            <p className="text is-2 is-3-sp ls-5 lh-9">
              屋上のロケーションを活かして、滑走前からワクワク感を演出！滑走用の専用袋に入ったらスタンバイ完了！すべり出しにむけての緊張感が高まります！
            </p>
          </div>
          <div className="flow-row">
            <div className="col6 sp-mb30">
              <div className="col6-inner nested">
                <Image filename="img-subezo3.jpg" />
              </div>
            </div>
            <div className="col6">
              <div className="row column">
                <div className="col sp-mb30">
                  <div className="col6-inner">
                    <Image filename="img-subezo4.jpg" />
                  </div>
                </div>
                <div className="col">
                  <div className="col6-inner">
                    <Image filename="img-subezo5.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className="title is-2 jp shadow3 sp-mb10">
            <TitleZekkei width={780} shadow="50.5%">
              滑走を何度でも楽しめる演出！
            </TitleZekkei>
          </h2>
          <div className="content mb60">
            <p className="text is-2 is-3-sp ls-5 lh-9">
              百道浜エリアの景色を一望しながらのすべり出し！天気が良い日は日光を感じる爽快感も！直線部分を過ぎると暗闇のらせん部分へ突入！音と光により更なる疾走感を演出します！
            </p>
          </div>

          <div className="flow-row">
            <div className="col6">
              <div className="subezo-ttl">
                <span className="text is-2 fwb">直線エリア</span>
                <h3 className="text is-2 fwb">上半分は透明なポリカを採用</h3>
              </div>
              <div className="col4-inner row">
                <Image filename="img-subezo9.jpg" />
              </div>
            </div>
            <div className="col6">
              <div className="subezo-ttl">
                <span className="text is-2 fwb">螺旋エリア</span>
                <h3 className="text is-2 fwb">
                  暗闇のうねりの中で
                  <b>光と音の演出！</b>
                </h3>
              </div>
              <div className="col4-inner row">
                <Image filename="img-subezo10.jpg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-13 bs-8" id="information">
        <div className="container">
          <div className="inner-slim bg-14">
            <h3 className="headline">
              <span>Information</span>
            </h3>

            <h4 className="text fwb is-3 is-4-sp mb15">料金・対象</h4>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,500円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>1,200円</b>
                        </dd>
                      </dl>
                      <p className="mt30">
                        各日程のレギュラー・ピークは空席情報カレンダーでご確認ください。
                      </p>
                      <p>
                        <a href="/ticket/" className="tx-link">
                          空席情報カレンダーはこちら
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>対象年齢</th>
                    <td>10歳～64歳</td>
                  </tr>
                  <tr>
                    <th>身長</th>
                    <td>130㎝以上</td>
                  </tr>
                  <tr>
                    <th>体重</th>
                    <td>35㎏以上</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <article>
              <p className="mb15">【以下に該当する方はご利用いただけません】</p>
              <ol className="mb30">
                <li>
                  ・当施設は安全面の理由から、身長・体重・年齢で下記利用制限を設けております。また、12歳以下の場合は保護者の承諾が必要となります。
                </li>
                <li>
                  ①9歳以下、65歳以上、身長130cm未満、着衣を含み体重が35kg未満の方
                </li>
                <li>
                  ②・酒気を帯びた方
                  <br />
                  ・妊娠中の方
                  <br />
                  ・心疾患をお持ちの方
                  <br />
                  ・歩行に介助が必要な方（滑走のための袋を装着するため）
                  <br />
                  ・その他健康上不安のある方
                  <br />
                  ・滑走袋が正しく（フィットした状態で）装着できない方
                </li>
              </ol>
            </article>
            <div className="inner-slim p00 bg-14">
              <Accordion
                Name="利用規約はこちら"
                Title="利用規約"
                Close="利用規約を閉じる"
                bg="bg-14"
                open
              >
                {/* <h3>段落タイトル段落タイトル段落タイトル</h3> */}
                <div className="content">
                  <p className="list-mark3">
                    ・当施設内での怪我、損失、損害、トラブルなどにつきまして、弊社の責めに帰すべき事由がある場合を除き、弊社は一切の責任を負いません。
                  </p>
                  <p className="list-mark3">
                    ・スタッフにより安全にご参加いただけないと判断した場合、参加をお断りする場合がございます。
                  </p>
                  <p className="list-mark3">
                    ・スタッフの指示には必ず従って下さい。指示及び注意事項に従わなかった場合の怪我などの障害及び損害については責任を負いかねます。
                  </p>
                  <p className="list-mark3">
                    ・安全確認のため、身長・体重の測定をお願いする場合がございます。測定後に利用制限に該当する場合はご参加をお断りさせていただきます。
                  </p>
                </div>
              </Accordion>
            </div>

            <article className="mt-5">
              {isTicket === '1' ? (
                <ModalContent ticket={ticket} uuid={uuid} page="subezo" />
              ) : null}
              {/* <div className="btn-group mt-5 mb-6">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>
                    新型コロナウイルス感染防止の対策について
                  </span>
                </Link>
              </div> */}
              <h4 className="text fwb is-3 is-4-sp mb15">注意事項</h4>
              <ol className="mb30">
                <li>・状況により、ご利用をお待ちいただく場合がございます。</li>
                <li>
                  ・スカートやワンピースを着用してのご利用はお控えください。
                </li>
                <li>
                  ・肘が露出しない服装もしくはアームカバー等を着用してご利用ください。
                </li>
                <li>
                  ・脱げやすいサンダルやヒール等でのご利用はお控えください。
                </li>
                <li>
                  ・ひどく汚れた靴、スパイク靴、濡れた靴でのご利用はお控えください。
                </li>
                <li>
                  ・手荷物（携帯電話、カメラなど身の回りの所持品含む）やマフラー、コート、装飾品（ネックレス・ブレスレット・ピアスなど外れる恐れのあるもの）は全てロッカーに預けていただきます。装飾品が外せない場合はご参加いただけません。
                </li>
              </ol>
            </article>
          </div>
        </div>
      </section>
      <section className="section sc-flow bg-14" id="flow">
        <div className="container">
          <h2 className="headline">
            <span>Flow</span>
          </h2>
          <div className="border-box bs-8 stack">
            <Image filename="img-flow-subezo.png" />
          </div>

          <div className="content border-box bg-12 subezo">
            <h3>8F 受付へお越しください</h3>
            <p>
              <span>1</span>
              受付で手続きをする
              <br />
              ・チケットをご提示ください。チケット情報の確認を行います。
              <br />
              ・ロッカー専用コインをお渡しします。
              <br />
            </p>
            <p>
              <span>2</span>
              同意書への記載。内容をよく確認のうえ記載をお願いいたします
            </p>
            <p>
              <span>3</span>
              専用ロッカーへの手荷物収納 ※手荷物は全てお預けください
            </p>
            <p>
              <span>4</span>
              スタートまで待機列にてお待ちください
            </p>
          </div>

          <div className="inner p00 bg-14">
            <div className="introduction">
              <p className="tac fwb">▼　その他の屋上アトラクションを見る　▼</p>
              <div className="row j-center colum-sp">
                <div className="col">
                  <Link to="/zekkei-brothers/">
                    <Image
                      filename="logo-zekkei-brothers-l.png"
                      alt="絶景3兄弟 SMBC日興証券"
                    />
                    <span className="text fwb">
                      絶景3兄弟 SMBC日興証券TOPページへ戻る
                    </span>
                  </Link>
                </div>
                <div className="col">
                  <Link to="/zekkei-brothers/nobozo/">
                    <Image
                      filename="logo-zekkei-nobozo.png"
                      alt="すべZO | 絶景3兄弟 SMBC日興証券"
                    />
                  </Link>
                </div>
                <div className="col">
                  <Link to="/zekkei-brothers/tsurizo/">
                    <Image
                      filename="logo-zekkei-tsurizo.png"
                      alt="つりZO | 絶景3兄弟 SMBC日興証券"
                    />
                  </Link>
                </div>
              </div>
            </div>
            {isTicket === '1' ? (
              <ModalContent ticket={ticket} uuid={uuid} page="subezo" />
            ) : null}
            {/* <div className="btn-group mt-5">
              <Link
                to="/news/info/20230311252/"
                className="btn-def caution-ticket-btn narrow"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <span>
                  新型コロナウイルス感染防止の対策について
                </span>
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Template;
