/**
 * Type: コンポーネント
 * What: アコーディオン
 */
// モーダルコンテンツ
import React, { useEffect, useState } from 'react';
import Image from '../util/Image';
import { Btn, Button, Text } from './Btn';
import Arrow from '../images/btn/btn-def-arrow.svg';

const ModalContent = (props) => {
  const [isModal, setIsModal] = useState(false);
  const [lang, setLang] = useState('ja');

  // モーダル展開
  const onClickModal = (e) => {
    e.preventDefault();
    setIsModal(true);
  };
  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(false);
  };

  useEffect(() => {
    /* eslint no-undef: 0 */
    if (typeof WOVN !== 'undefined') {
      setLang(WOVN.io.getCurrentLang().code);
    }
    /*    return () => {
      document.removeEventListener('scroll', onScroll);
    }; */
    // eslint-disable-next-line react/destructuring-assignment
  }, [isModal]);

  return (
    <>
      <div className={`modal e-modal ${isModal ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className="modal-content">
          <div className="e-modal-image">
            <figure className="image" />
          </div>
          <div className="e-modal-content">
            <h3 className="ttl c-4 mb10">チケット購入、同意書、コンテンツ利用規約</h3>
            <h4 className="mb30">同意書・コンテンツ資料規約をご確認ください。</h4>
            {props.description ? (
              <p className="is-size-6 has-text-align-center has-text-weight-bold has-text-dark mb-2">{props.description}</p>
            ) : null}
            {props.caution ? (
              <p
                className="attention"
                dangerouslySetInnerHTML={{ __html: props.caution }}
              />
            ) : null}
            {
              <div className="btn-wrap mb-3">
                <Button
                  href={`${props.ticket}#/order?lang=ja&id=${props.uuid}`}
                  className="btn-buy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.titleInner ? props.titleInner : 'チケット購入'}
                </Button>
              </div>
            }

            {/* ${lang} */}
            <div className="btn-wrap mb-3">
              <Button
                href={`/files/zekkei-brothers/rule-${props.page}-${lang}.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                同意書・コンテンツ利用規約(PDF)
              </Button>
            </div>
            <p>※ご利用当日に利用同意書が必要です。</p>
            <p>※12歳以下の場合、保護者の方（18歳以上）のご署名が必要です。ご利用する当日は、保護者のサインが入った同意書を必ずご持参ください。</p>
          </div>
        </div>
      </div>

      <div className={`btn-wrap mb-5${props.description ? ' flx-col' : null}`}>
        {props.description ? (
          <p className="desc-pre">{props.description}</p>
        ) : null}
        <a
          href="#"
          data-sal="slide-up"
          data-sal-easing="ease-out-expo"
          onClick={(e) => onClickModal(e)}
          className="btn-regular btn-buy"
        >
          <span>{props.title ? props.title : 'チケット購入'}</span>
          <img src={Arrow} alt="" />
        </a>
      </div>
    </>
  );
};

export default ModalContent;
