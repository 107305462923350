/**
 * Type: ユーティリティーパーツ
 * What: 営業時間APIと現在時刻の差分を確認して営業中かどうかチェックする
 */
import moment from 'moment/moment';

const DiffTimeFunc = (start, end, openComment, closeComment) => {
  const time = moment();
  const beforeTime = moment(`${start !== null ? start : '10:00'}`, 'hh:mm');
  const afterTime = moment(`${end !== null ? end : '23:00'}`, 'hh:mm');
  const diff = time.isBetween(beforeTime, afterTime);

  return diff ? openComment : closeComment;
};

export default DiffTimeFunc;
