/**
 * Type: 投稿コンポーネント
 * What: TOPページ用最新10件の投稿リスト(TOP固定を除く)
 */
import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import axios from 'axios';
import moment from 'moment/moment';
import Image from '../../util/Image';
import RfStatusFunc from '../../util/rfStatus';
import DiffTimeFunc from '../../util/diffTime';
import HtmlEscape from '../../util/htmlEscape';

// RF運行状況コンポーネント
const RfStatusComponent = (props) => {
  const data = useStaticQuery(graphql`
  {
    allWordpressPage(filter: { wordpress_id: { in: 2 } }) {
      nodes {
        acf {
          status_tsuri_zo
          status_sube_zo
          status_nobo_zo
          text_detail
          fixed
          detail_sube_zo
          detail_tsuri_zo
          detail_nobo_zo
        }
      }
    }
  }
`);

  const rf = data.allWordpressPage.nodes[0].acf;

  const statusNoboZo = rf.status_nobo_zo;
  const statusSubeZo = rf.status_sube_zo;
  const statusTsuriZo = rf.status_tsuri_zo;
  const detailNoboZo = rf.detail_nobo_zo;
  const detailSubeZo = rf.detail_sube_zo;
  const detailTsuriZo = rf.detail_tsuri_zo;
  const statusFixed = rf.fixed;
  const textDetail = rf.text_detail;

  const SCEDULE_ENDPOINT = '/api-data/schedule.json';

  const [fetchReady, setFetchReady] = useState(false);
  const [startTimeSubeZo, setStartTimeSubeZo] = useState(null);
  const [startTimeNoboZo, setStartTimeNoboZo] = useState(null);
  const [startTimeTsuriZo, setStartTimeTsuriZo] = useState(null);
  const [endTimeSubeZo, setEndTimeSubeZo] = useState(null);
  const [endTimeNoboZo, setEndTimeNoboZo] = useState(null);
  const [endTimeTsuriZo, setEndTimeTsuriZo] = useState(null);

  // APIをfetch
  const dataFetch = async () => {
    const date = moment().format('YYYY/M/D');
    await axios
      .get(SCEDULE_ENDPOINT)
      .then((results) => {
        const api = results.data.filter((val) => val[0] === date);
        const subezo = api[0][20].split('～');
        const nobozo = api[0][23].split('～');
        const tsurizo = api[0][26].split('～');
        setStartTimeSubeZo(subezo[0]);
        setStartTimeNoboZo(nobozo[0]);
        setStartTimeTsuriZo(tsurizo[0]);
        setEndTimeSubeZo(subezo[1]);
        setEndTimeNoboZo(nobozo[1]);
        setEndTimeTsuriZo(tsurizo[1]);
        setFetchReady(true);
      })
      .catch(() => {
      // console.log('APIエラー');
      });
  };

  useEffect(() => {
    dataFetch();
  }, [props.location]);

  const subeZoMark = `status-${DiffTimeFunc(startTimeSubeZo, endTimeSubeZo, statusSubeZo, 'suspended')}`;
  const tsuriZoMark = `status-${DiffTimeFunc(startTimeTsuriZo, endTimeTsuriZo, statusTsuriZo, 'suspended')}`;
  const noboZoMark = `status-${DiffTimeFunc(startTimeNoboZo, endTimeNoboZo, statusNoboZo, 'suspended')}`;

  return (
    <>
      {props.col === 'all' ? (
        statusFixed !== 'hidden' && (
          <div className="status-comment">
            <div className={`status-comment-inner${statusFixed !== 'free' ? ' fixed' : ''}`}>
              {statusFixed === 'free' ? (
                <p dangerouslySetInnerHTML={{
                  __html: HtmlEscape(textDetail),
                }} />
              ) : (
                statusFixed
              )}
            </div>
          </div>
        )
      ) : null}
      <div className={`operation-status ${props.col !== 'all' ? 'only' : ''}`}>
        <div className="row">
          { props.col === 'all' || props.col === '1' ? (
            <div className="col">
              <article className="operation">

                {props.col !== '1' && (
                <div className="operation-header">
                  <Link to="/zekkei-brothers/subezo/"><Image src="img-suberizo.png" /></Link>
                </div>
                )}
                <div className={`operation-body ${fetchReady ? subeZoMark : ''}`}>
                  <div className="field is-grouped">
                    <div
                      className="decision"
                      data-sal="zoom-in"
                      data-sal-delay="300"
                      data-sal-easing="ease-out-expo"
                    />
                    <div className="tags">
                      <span className="tag">
                        {DiffTimeFunc(startTimeSubeZo, endTimeSubeZo, RfStatusFunc(statusSubeZo), '停止中')}
                      </span>
                      <span className="tag">
                        {DiffTimeFunc(startTimeSubeZo, endTimeSubeZo, detailSubeZo, '営業時間外')}
                      </span>
                    </div>
                  </div>
                </div>

              </article>
            </div>
          ) : null}
          { props.col === 'all' || props.col === '2' ? (
            <div className="col">
              <article className="operation">
                {props.col !== '2' && (
                <div className="operation-header">
                  <Link to="/zekkei-brothers/tsurizo/"><Image src="img-turizo.png" /></Link>
                </div>
                )}
                <div className={`operation-body ${fetchReady ? tsuriZoMark : ''}`}>
                  <div className="field is-grouped">
                    <div
                      className="decision"
                      data-sal="zoom-in"
                      data-sal-delay="300"
                      data-sal-easing="ease-out-expo"
                    />
                    <div className="tags">
                      <span className="tag">
                        {DiffTimeFunc(startTimeTsuriZo, endTimeTsuriZo, RfStatusFunc(statusTsuriZo), '停止中')}
                      </span>
                      <span className="tag">
                        {DiffTimeFunc(startTimeTsuriZo, endTimeTsuriZo, detailTsuriZo, '営業時間外')}
                      </span>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ) : null}
          { props.col === 'all' || props.col === '3' ? (
            <div className="col">
              <article className="operation">
                {props.col !== '3' && (
                <div className="operation-header">
                  <Link to="/zekkei-brothers/nobozo/"><Image src="img-noborizo.png" /></Link>
                </div>
                )}
                <div className={`operation-body ${fetchReady ? noboZoMark : ''}`}>
                  <div className="field is-grouped">
                    <div
                      className="decision"
                      data-sal="zoom-in"
                      data-sal-delay="300"
                      data-sal-easing="ease-out-expo"
                    />
                    <div className="tags">
                      <span className="tag">
                        {DiffTimeFunc(startTimeNoboZo, endTimeNoboZo, RfStatusFunc(statusNoboZo), '停止中')}
                      </span>
                      <span className="tag">
                        {DiffTimeFunc(startTimeNoboZo, endTimeNoboZo, detailNoboZo, '営業時間外')}
                      </span>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default RfStatusComponent;
